<template>
  <div class="w-p-100 h-p-100">
    <div class="h-calc-45 w-p-100">
      <iframe
        :src="viewsrc"
        width="100%"
        height="100%"
        v-if="filetype != 'img' && filetype != 'video'"
        border="none"
      ></iframe>
      <video
        ref="video_item"
        v-if="filetype == 'video'"
        id="video_item"
        :src="viewsrc"
        controls
        disablePictureInPicture="true"
      ></video>
      <el-image
        :src="viewsrc"
        v-if="filetype == 'img'"
        style="width:100%"
      ></el-image>
    </div>
    <div class="size-18 w-p-100 flex alignCenter justifyCenter">
      <div
        class="h-35 bg-0F60A7 flex alignCenter justifyCenter  w-p-100 color-fff padding-5"
        @click="goback()"
      >
        返回
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import http from "../../../api/http";
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const filetype = ref("");
    const viewsrc = ref("");
    const imgurl = http.getImgUrl();
    const demo = ref(null);
    // PDF
    const pdfh5 = ref(null);
    const getDetail = () => {
      http
        .get({
          method: "detail",
          id: route.params.id,
          t: "CT_TRAIN_DOCUMENT"
        })
        .then(res => {
          var maps = res.map;
          var attachment1 = maps.attachment1;
          var tlist = attachment1[0].document_path.split(".");
          filetype.value =
            tlist[tlist.length - 1] == "mp4"
              ? "video"
              : tlist[tlist.length - 1] == "jpg" ||
                tlist[tlist.length - 1] == "png"
              ? "img"
              : tlist[tlist.length - 1] == "pdf" ||
                tlist[tlist.length - 1] == "PDF"
              ? "pdf"
              : "";
          viewsrc.value =
            tlist[tlist.length - 1] == "mp4" ||
            tlist[tlist.length - 1] == "jpg" ||
            tlist[tlist.length - 1] == "png" ||
            filetype.value == "pdf"
              ? imgurl + attachment1[0].document_path
              : "https://view.officeapps.live.com/op/view.aspx?src=" +
                imgurl +
                attachment1[0].document_path;
          if (filetype.value == "pdf") {
            pdfh5.value = new Pdfh5(demo.value, {
              pdfurl: viewsrc.value
            });
            pdfh5.value.on("complete", function(status, msg, time) {
              console.log(
                "状态：" +
                  status +
                  "，信息：" +
                  msg +
                  "，耗时：" +
                  time +
                  "毫秒，总页数："
              );
            });
          }
        });
    };

    const goback = () => {
      router.push({
        path: "/" + route.params.active,
        query: { train_type: route.query.train_type }
      });
    };
    onMounted(() => {
      getDetail();
    });
    return {
      router,
      filetype,
      viewsrc,
      demo,
      goback
    };
  }
};
</script>
